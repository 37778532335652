<template>
    <div>
        <div class="mx-auto mt-8">
            <img class="mx-auto h-16 w-auto" src="/img/logo.svg" alt="SendBack logo" />
        </div>
        <div class="mx-auto flex max-w-9xl flex-col md:px-8 xxl:px-0">
            <main class="flex-1">
                <slot />
            </main>
        </div>
    </div>
</template>
